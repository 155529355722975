.header {
    &.bg-light {
        background: $blue;
        background: -webkit-linear-gradient(left, #0287e6, #06bbf0);
        background: -moz-linear-gradient(left, #0287e6, #06bbf0);
        background: -ms-linear-gradient(left, #0287e6, #06bbf0);
        background: -o-linear-gradient(left, #0287e6, #06bbf0);
        background: linear-gradient(left, #0287e6, #06bbf0);
    }
    .navbar-header {
        height: 50px;
        .btn-link {
            color: #fff;
        }
        .navbar-brand {
            color: #fff;
            font-weight: normal;
            .logo {
                float: left;
                margin: 15px 5px 0 0;
            }
            h1 {
                float: left;
                display: inline-block;
                height: 50px;
                line-height: 50px;
                margin: 0;
                font-size: 18px;
            }
            small {
                float: left;
                display: inline-block;
                height: 20px;
                line-height: 20px;
                margin: 18px 0 0 5px;
                font-size: 12px;
            }
        }
    }
    .navbar-nav {
        >li {
            .text {
                height: 50px;
                line-height: 50px;
                margin-right: 15px;
                color: #fff;
                span {
                    margin-left: 5px;
                }
            }
            >a {
                height: 50px;
                line-height: 50px;
                padding: 0 12px;
                &:hover,
                &:active,
                &:focus {
                    background-color: $blue2;
                }
                .icon {
                    color: #fff;
                    font-size: 20px;
                }
            }
        }
        .dropdown-toggle {
            background: none;
        }
        .dropdown-menu {
            &.dropdown-menu-user {
                left: -100%;
                right: auto;
            }
            &.dropdown-menu-msg {
                >.arrow {
                    left: auto;
                    right: 20px;
                    &:after {
                        border-bottom-color: #e4e4e4;
                    }
                }
                .list-group {
                    a {
                        background: #fff;
                        &:hover {
                            background: $c1;
                        }
                        p {
                            margin: 0;
                        }
                    }
                }
                .panel-heading{
                    border-bottom:0;
                    text-align: center;
                }
                .panel-footer {
                    border-top:0;
                    text-align: center;
                    a {
                        color: $blue;
                    }
                }
            }
        }
    }
}
