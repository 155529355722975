::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    //border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    //border-radius: 8px;
    background-color: #afafaf;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #717171;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
    //border-radius: 8px;
    background-color: #e4e4e4;
}