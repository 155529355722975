@at-root {
    @font-face {
        font-family: $iconfontName;
        src: url('../fonts/jzyiconfont.eot?');
        src: url('../fonts/jzyiconfont.eot?#iefix') format('embedded-opentype'), url('../fonts/jzyiconfont.woff?') format('woff'), url('../fonts/jzyiconfont.ttf?') format('truetype'), url('../fonts/jzyiconfont.svg?#iconfont') format('svg');
    }
}

[class^="icon-jzy-"]:before,
[class*=" icon-jzy-"]:before{
    content:"";
    font-family: $iconfontName;
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-jzy-shuaxin:before { content: "\e665"; }

.icon-jzy-download:before { content: "\e6c8"; }

.icon-jzy-tuding1:before { content: "\e643"; }

.icon-jzy-guanbi:before { content: "\e611"; }

.icon-jzy-tianjia:before { content: "\e77e"; }

.icon-jzy-user:before { content: "\e62d"; }

.icon-jzy-users:before { content: "\e6a3"; }

.icon-jzy-tongji:before { content: "\e61f"; }

.icon-jzy-xiaoxi:before { content: "\e6b6"; }

.icon-jzy-user2:before { content: "\e686"; }

.icon-jzy-tuichu:before { content: "\e7de"; }

.icon-jzy-lingdang:before { content: "\e64b"; }

.icon-jzy-zhankai:before { content: "\e62c"; }

.icon-jzy-shouqi:before { content: "\e63e"; }

.icon-jzy-shenhe:before { content: "\e65f"; }

.icon-jzy-xitong:before { content: "\e6fa"; }

.icon-jzy-gaikuang:before { content: "\e952"; }

.icon-jzy-gujian:before { content: "\e68d"; }

.icon-jzy-xiangmu:before { content: "\e600"; }

.icon-jzy-app:before { content: "\e623"; }

.icon-jzy-tuding2:before { content: "\e953"; }