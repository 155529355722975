.panel-tab {
    >.panel-heading {
        border-top: 1px solid $borc;
        .nav-tabs {
            >li {
                >a{
                    font-size:14px;
                }
                &.active {
                    >a {

                        box-shadow:inset 0 3px 0px 0px $blue;
                    }
                }
            }
        }
    }
    >.panel-body {
        padding: 0;
        .toolbar-jzy {
            padding: 10px 20px;
        }
    }
}
