$blue:#1f92e2; //蓝色
$blue2:#1e84cf;
$fc:#666;
$fc2:#646464;
$fc3:#333;
$fc4:#999;
$c1:#f9f9f9; //侧栏灰色
$c2:#f4f4f4; //侧栏头部灰色
$c3:#f2f2f2; //侧栏选中灰色
$c4:#ffbc1a; //黄色
$borc:#e3e3e3; //边框灰色
$iconfontName:"jizhiyun";
$iconfontFileName:"jzyiconfont";
$iconfontClassName:"icon-jzy-";
$iconfontPath:"../fonts/";
.c-red {
    color: #fb6b5b;
}

.c-blue {
    color: $blue;
}

.c-green {
    color: #53b567;
}

.c-yellow {
    color: $c4;
}

.c-9 {
    color: $fc4;
}

.c-6 {
    color: $fc;
}