.form-group {
    line-height: 28px;
    margin: 0 20px 0 0;
    @include clearfix();
    input[type=file] {
        position: relative;
        top: 2px;
        border: 0;
        border-radius: 0;
    }
    textarea {
        width: 100%;
        height: 100px;
        padding: 5px 10px;
        border-radius: 2px;
        border: 1px solid $borc;
        resize: none;
    }
    img {
        width: 100%;
        max-width: 300px;
        max-height: 140px;
    }
    p {
        float: left;
        width: 90%;
        margin: 0;
    }
    .form-text {
        float: left;
        margin-right: 5px;
        color: $fc4;
        text-align: right;
    }
    .form-control {
        float: left;
        width: 160px;
        height: 28px;
        line-height: 28px;
        padding: 0 12px;
        border-radius: 2px;
        border: 1px solid $borc;
    }
    .radio-custom,
    .checkbox-custom {
        margin: 0 15px 0 0;
        >i.checked:before,
        .checkbox-custom>i.checked:before {
            color: $blue;
        }
    }
    .btn {
        height: 28px;
        line-height: 28px;
        padding: 0 15px;
        &.btn-default {
            color: $c3;
            background: #fff;
            box-shadow: none;
        }
        &.btn-info {
            background: $blue;
        }
    }
}

.ul-checkbox {
    ul {
        padding-left: 20px;
        list-style: none;
        li {
            position: relative;
            label {
                margin-bottom: 0;
            }
        }
    }
    .ul-checkbox-two {
        >li {
            &:before,
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 18px;
                left: 5px;
                bottom: 15px;
                border-left: 1px dashed #ccc;
            }
        }
    }
    .ul-checkbox-three {
        >li {
            &:before,
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 12px;
                left: -10px;
                width: 10px;
                border-top: 1px dashed #ccc;
            }
        }
    }
}

.panel-form {
    padding: 20px;
    @include clearfix();
    .form-group {
        margin-bottom: 20px;
        .form-text {
            width: 60px;
        }
    }
}

.btn {
    i {
        margin-right: 10px;
        vertical-align: middle;
    }
}

.edit-box {
    border: 1px solid $borc;
    border-radius: 2px;
    .btn-editor {
        height: 42px;
        background: #fbfbfb;
        @include clearfix();
        .btn-group {
            float: left;
            >.btn {
                float: left;
                height: 42px;
                line-height: 18px;
                padding: 15px;
                border: 0;
                background: none;
                i {
                    margin: 0;
                }
                &.btn-info {
                    box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, 0.1);
                    i {
                        color: $fc3;
                    }
                }
                &.btn-fontsize {
                    i,
                    b {
                        vertical-align: super;
                    }
                    b {
                        position: relative;
                        top: -2px;
                    }
                }
            }
        }
    }
    .form-editor {
        width: 100%;
        height: 235px;
        border: 0;
        overflow-y: auto;
    }
}
