html.app,
html.app>body {
    overflow: hidden;
}

.body-bg {
    background-color: #ececec;
}

.iframe-jzy {
    position: relative;
    width: 100%;
    height: 100%;
    border: 0;
}

.main-jzy {
    .main-jzy-head {
        position: relative;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
    }
    .main-jzy-cont {
        position: relative;
        z-index: 1;
        padding: 20px;
        .main-jzy-cont-null {
            margin-top: 10%;
            color: $fc4;
            font-size: 12px;
            text-align: center;
            .icon {
                font-size: 50px;
            }
            .btn {
                padding: 12px 35px;
                font-size: 18px;
            }
        }
    }
}

.toolbar-jzy {
    padding: 0 20px;
    border-bottom: 1px solid $borc;
    background: #fff;
    @include clearfix();
    .breadcrumb {
        height: 43px;
        line-height: 43px;
        margin-bottom: 0;
        padding: 0;
        border: 0;
        >li {
            font-size: 14px;
            &:before,
            &::before {
                display: none;
            }
            a {
                color: $blue;
            }
            .icon {
                margin: 0 10px;
                color: $fc;
                font-size: 16px;
            }
            &.active {
                color: $fc3;
            }
        }
    }
    .speedbtns {
        margin-bottom: 0;
        li {
            float: left;
            list-style: none;
            margin-left: 15px;
            a {
                display: block;
                height: 44px;
                font-size: 12px;
                .icon {
                    position: relative;
                    top: 14px;
                    font-size: 20px;
                }
                &:hover {
                    opacity: 0.8;
                }
                span {
                    position: relative;
                    top: 10px;
                }
            }
            .btn {
                position: relative;
                top: 5px;
                height: 30px;
                line-height: 30px;
                padding: 0 12px;
                font-size: 14px;
            }
        }
    }
    .filtrate {
        padding: 6px 0;
        font-size: 12px;
        input {
            height: 28px;
            line-height: 28px;
        }
        .text {
            height: 28px;
            line-height: 28px;
            margin-right: 10px;
            color: $fc4;
        }
        .btn {
            font-size: 12px;
            padding: 5px 18px;
            box-shadow: none;
        }
        .btn-default {
            color: $fc!important;
            border-color: $borc;
            background: none;
            &:hover {
                background: $c1;
            }
            &.active {
                color: #fff!important;
                box-shadow: none;
                background: $blue;
            }
        }
        .form-control {
            width: 160px;
            height: 28px;
            line-height: 28px;
            padding: 0 12px;
        }
        .daterangepicker-input {
            width: 180px;
            font-size: 12px;
        }
        .choose-shgs {
            line-height: 28px;
            border: 1px solid $borc;
            background: $c3;
            input {
                width: 150px;
                padding: 0 10px;
                margin-right: 20px;
                border: 0;
                outline: 0;
                background: none;
            }
            a {
                margin-right: 10px;
            }
        }
    }
}
