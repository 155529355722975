//清除浮动
@mixin clearfix() {
    &:before,
    &:after {
        content: " ";
        display: block;
        *zoom: 1;
    }
    &:after {
        clear: both;
    }
}

// 是否可选
@mixin user-select($select) {
    -webkit-user-select: $select;
    -moz-user-select: $select;
    -ms-user-select: $select; // IE10+
    user-select: $select;
}
