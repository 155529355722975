.aside-nav {
    section {
        &.w-f {
            top: 44px;
            bottom: 0;
        }
    }
    border-right: 1px solid $borc;
    &.bg-light {
        background-color: $c1;
    }
    .aside-nav-header {
        height: 44px;
        line-height: 44px;
        min-height: 44px;
        border-bottom: 1px solid $borc;
        background-color: $c2;
        .aside-nav-header-tit {
            display: inline-block;
            line-height: 44px;
            margin-left:34px;
            color: $fc2;
        }
        .aside-nav-header-icon {
            width: 30px;
            height: 30px;
            line-height: 30px;
            margin-top: 7px;
            color: $fc;
            font-size: 16px;
            text-align: center;
        }
    }
    .nav-primary {
        border: 0;
        >ul {
            &.nav {
                >li {
                    >a {
                        position: relative;
                        border: 0;
                        color: $fc;
                        font-weight: normal;
                        @include clearfix();
                        &:before,
                        &::before {
                            content: '';
                            display: none;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            width: 3px;
                            height: 24px;
                            margin-top: -12px;
                            background-color: $blue;
                        }
                        i {
                            border: 0;
                            margin-right: 0;
                            &.icon {
                                color: $fc;
                                font-size: 18px;
                            }
                            &.nav-primary-arrow {
                                display: none;
                                width: 10px;
                            }
                        }
                        .badge{
                            position:absolute;
                            top:50%;
                            right:30px;
                            margin-top:-9px;
                        }
                    }
                    &:hover {
                        >a {
                            color: $blue;
                            background-color: $c3;
                            i {
                                color: $blue;
                            }
                        }
                    }
                    &.active {
                        >a {
                            color: $blue;
                            border: 0;
                            background-color: $c3;
                            &:before,
                            &::before {
                                display: block;
                            }
                            i {
                                color: $blue;
                                &.nav-primary-arrow {
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .nav-xs {
        .nav-primary {
            >ul {
                &.nav {
                    >li {
                        >a {
                            height: 44px;
                            span,
                            .icon {
                                height: 44px;
                                color: $fc;
                            }
                            span {
                                padding: 0 10px;
                            }
                            .icon {
                                line-height: 44px;
                            }
                        }
                        &:hover {
                            >a {
                                span,
                                .icon {
                                    color: $blue;
                                }
                                i {
                                    &.icon {
                                        margin-top: -44px;
                                    }
                                }
                            }
                        }
                        &.active {
                            >a {
                                span {
                                    color: $blue;
                                }
                                i {
                                    &.icon {
                                        margin-top: -44px;
                                    }
                                    &.nav-primary-arrow {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
