.c-red {
  color: #fb6b5b;
}

.c-blue {
  color: #1f92e2;
}

.c-green {
  color: #53b567;
}

.c-yellow {
  color: #ffbc1a;
}

.c-9 {
  color: #999;
}

.c-6 {
  color: #666;
}

@font-face {
  font-family: "jizhiyun";
  src: url("../fonts/jzyiconfont.eot?");
  src: url("../fonts/jzyiconfont.eot?#iefix") format("embedded-opentype"), url("../fonts/jzyiconfont.woff?") format("woff"), url("../fonts/jzyiconfont.ttf?") format("truetype"), url("../fonts/jzyiconfont.svg?#iconfont") format("svg");
}

[class^="icon-jzy-"]:before,
[class*=" icon-jzy-"]:before {
  content: "";
  font-family: "jizhiyun";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-jzy-shuaxin:before {
  content: "\e665";
}

.icon-jzy-download:before {
  content: "\e6c8";
}

.icon-jzy-tuding1:before {
  content: "\e643";
}

.icon-jzy-guanbi:before {
  content: "\e611";
}

.icon-jzy-tianjia:before {
  content: "\e77e";
}

.icon-jzy-user:before {
  content: "\e62d";
}

.icon-jzy-users:before {
  content: "\e6a3";
}

.icon-jzy-tongji:before {
  content: "\e61f";
}

.icon-jzy-xiaoxi:before {
  content: "\e6b6";
}

.icon-jzy-user2:before {
  content: "\e686";
}

.icon-jzy-tuichu:before {
  content: "\e7de";
}

.icon-jzy-lingdang:before {
  content: "\e64b";
}

.icon-jzy-zhankai:before {
  content: "\e62c";
}

.icon-jzy-shouqi:before {
  content: "\e63e";
}

.icon-jzy-shenhe:before {
  content: "\e65f";
}

.icon-jzy-xitong:before {
  content: "\e6fa";
}

.icon-jzy-gaikuang:before {
  content: "\e952";
}

.icon-jzy-gujian:before {
  content: "\e68d";
}

.icon-jzy-xiangmu:before {
  content: "\e600";
}

.icon-jzy-app:before {
  content: "\e623";
}

.icon-jzy-tuding2:before {
  content: "\e953";
}

.header.bg-light {
  background: #1f92e2;
  background: -webkit-linear-gradient(left, #0287e6, #06bbf0);
  background: -moz-linear-gradient(left, #0287e6, #06bbf0);
  background: -ms-linear-gradient(left, #0287e6, #06bbf0);
  background: -o-linear-gradient(left, #0287e6, #06bbf0);
  background: linear-gradient(left, #0287e6, #06bbf0);
}

.header .navbar-header {
  height: 50px;
}

.header .navbar-header .btn-link {
  color: #fff;
}

.header .navbar-header .navbar-brand {
  color: #fff;
  font-weight: normal;
}

.header .navbar-header .navbar-brand .logo {
  float: left;
  margin: 15px 5px 0 0;
}

.header .navbar-header .navbar-brand h1 {
  float: left;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  margin: 0;
  font-size: 18px;
}

.header .navbar-header .navbar-brand small {
  float: left;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  margin: 18px 0 0 5px;
  font-size: 12px;
}

.header .navbar-nav > li .text {
  height: 50px;
  line-height: 50px;
  margin-right: 15px;
  color: #fff;
}

.header .navbar-nav > li .text span {
  margin-left: 5px;
}

.header .navbar-nav > li > a {
  height: 50px;
  line-height: 50px;
  padding: 0 12px;
}

.header .navbar-nav > li > a:hover, .header .navbar-nav > li > a:active, .header .navbar-nav > li > a:focus {
  background-color: #1e84cf;
}

.header .navbar-nav > li > a .icon {
  color: #fff;
  font-size: 20px;
}

.header .navbar-nav .dropdown-toggle {
  background: none;
}

.header .navbar-nav .dropdown-menu.dropdown-menu-user {
  left: -100%;
  right: auto;
}

.header .navbar-nav .dropdown-menu.dropdown-menu-msg > .arrow {
  left: auto;
  right: 20px;
}

.header .navbar-nav .dropdown-menu.dropdown-menu-msg > .arrow:after {
  border-bottom-color: #e4e4e4;
}

.header .navbar-nav .dropdown-menu.dropdown-menu-msg .list-group a {
  background: #fff;
}

.header .navbar-nav .dropdown-menu.dropdown-menu-msg .list-group a:hover {
  background: #f9f9f9;
}

.header .navbar-nav .dropdown-menu.dropdown-menu-msg .list-group a p {
  margin: 0;
}

.header .navbar-nav .dropdown-menu.dropdown-menu-msg .panel-heading {
  border-bottom: 0;
  text-align: center;
}

.header .navbar-nav .dropdown-menu.dropdown-menu-msg .panel-footer {
  border-top: 0;
  text-align: center;
}

.header .navbar-nav .dropdown-menu.dropdown-menu-msg .panel-footer a {
  color: #1f92e2;
}

.aside-nav {
  border-right: 1px solid #e3e3e3;
}

.aside-nav section.w-f {
  top: 44px;
  bottom: 0;
}

.aside-nav.bg-light {
  background-color: #f9f9f9;
}

.aside-nav .aside-nav-header {
  height: 44px;
  line-height: 44px;
  min-height: 44px;
  border-bottom: 1px solid #e3e3e3;
  background-color: #f4f4f4;
}

.aside-nav .aside-nav-header .aside-nav-header-tit {
  display: inline-block;
  line-height: 44px;
  margin-left: 34px;
  color: #646464;
}

.aside-nav .aside-nav-header .aside-nav-header-icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-top: 7px;
  color: #666;
  font-size: 16px;
  text-align: center;
}

.aside-nav .nav-primary {
  border: 0;
}

.aside-nav .nav-primary > ul.nav > li > a {
  position: relative;
  border: 0;
  color: #666;
  font-weight: normal;
}

.aside-nav .nav-primary > ul.nav > li > a:before, .aside-nav .nav-primary > ul.nav > li > a:after {
  content: " ";
  display: block;
  *zoom: 1;
}

.aside-nav .nav-primary > ul.nav > li > a:after {
  clear: both;
}

.aside-nav .nav-primary > ul.nav > li > a:before, .aside-nav .nav-primary > ul.nav > li > a::before {
  content: '';
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  width: 3px;
  height: 24px;
  margin-top: -12px;
  background-color: #1f92e2;
}

.aside-nav .nav-primary > ul.nav > li > a i {
  border: 0;
  margin-right: 0;
}

.aside-nav .nav-primary > ul.nav > li > a i.icon {
  color: #666;
  font-size: 18px;
}

.aside-nav .nav-primary > ul.nav > li > a i.nav-primary-arrow {
  display: none;
  width: 10px;
}

.aside-nav .nav-primary > ul.nav > li > a .badge {
  position: absolute;
  top: 50%;
  right: 30px;
  margin-top: -9px;
}

.aside-nav .nav-primary > ul.nav > li:hover > a {
  color: #1f92e2;
  background-color: #f2f2f2;
}

.aside-nav .nav-primary > ul.nav > li:hover > a i {
  color: #1f92e2;
}

.aside-nav .nav-primary > ul.nav > li.active > a {
  color: #1f92e2;
  border: 0;
  background-color: #f2f2f2;
}

.aside-nav .nav-primary > ul.nav > li.active > a:before, .aside-nav .nav-primary > ul.nav > li.active > a::before {
  display: block;
}

.aside-nav .nav-primary > ul.nav > li.active > a i {
  color: #1f92e2;
}

.aside-nav .nav-primary > ul.nav > li.active > a i.nav-primary-arrow {
  display: inline-block;
}

@media (min-width: 768px) {
  .nav-xs .nav-primary > ul.nav > li > a {
    height: 44px;
  }
  .nav-xs .nav-primary > ul.nav > li > a span,
  .nav-xs .nav-primary > ul.nav > li > a .icon {
    height: 44px;
    color: #666;
  }
  .nav-xs .nav-primary > ul.nav > li > a span {
    padding: 0 10px;
  }
  .nav-xs .nav-primary > ul.nav > li > a .icon {
    line-height: 44px;
  }
  .nav-xs .nav-primary > ul.nav > li:hover > a span,
  .nav-xs .nav-primary > ul.nav > li:hover > a .icon {
    color: #1f92e2;
  }
  .nav-xs .nav-primary > ul.nav > li:hover > a i.icon {
    margin-top: -44px;
  }
  .nav-xs .nav-primary > ul.nav > li.active > a span {
    color: #1f92e2;
  }
  .nav-xs .nav-primary > ul.nav > li.active > a i.icon {
    margin-top: -44px;
  }
  .nav-xs .nav-primary > ul.nav > li.active > a i.nav-primary-arrow {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #afafaf;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #717171;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background-color: #e4e4e4;
}

.modal-backdrop {
  opacity: 0 !important;
}

.panel-table .panel-heading {
  background: #f9f9f9;
}

.panel-table .panel-heading .form-group {
  line-height: 28px;
  margin: 0 20px 0 0;
}

.panel-table .table th,
.panel-table .table td {
  vertical-align: middle;
}

.panel-table .table thead tr th {
  padding: 12px 15px;
  background: #f4f4f4;
  font-weight: normal;
}

.panel-table .table tbody tr td {
  padding: 10px 15px;
  color: #666;
}

.panel-table .table tbody tr td .img-sm {
  width: 24px;
  height: 24px;
}

.panel-table .table tbody tr td a:first-child {
  margin-left: 0;
}

.panel-table .table tbody tr td p {
  position: relative;
  margin-bottom: 0;
}

.panel-table .table tbody tr .icon-star i {
  color: #ffbc1a;
  font-size: 16px;
}

.panel-table .table tbody tr.newmsg .dot {
  position: relative;
  left: -10px;
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-left: -6px;
  border-radius: 50%;
  background: #1f92e2;
}

.panel-table .table tbody tr .btn-group {
  cursor: pointer;
}

.panel-table .table tbody tr .btn-group.open .dropdown-toggle {
  box-shadow: none;
}

.panel-table .table tbody tr:hover {
  background: #f9f9f9;
}

.panel-table .table tfoot tr td {
  padding: 15px 15px;
}

.form-group {
  line-height: 28px;
  margin: 0 20px 0 0;
}

.form-group:before, .form-group:after {
  content: " ";
  display: block;
  *zoom: 1;
}

.form-group:after {
  clear: both;
}

.form-group input[type=file] {
  position: relative;
  top: 2px;
  border: 0;
  border-radius: 0;
}

.form-group textarea {
  width: 100%;
  height: 100px;
  padding: 5px 10px;
  border-radius: 2px;
  border: 1px solid #e3e3e3;
  resize: none;
}

.form-group img {
  width: 100%;
  max-width: 300px;
  max-height: 140px;
}

.form-group p {
  float: left;
 margin: 0;
}

.form-group .form-text {
  float: left;
  margin-right: 5px;
  color: #999;
  text-align: right;
}

.form-group .form-control {
  float: left;
  width: 160px;
  height: 28px;
  line-height: 28px;
  padding: 0 12px;
  border-radius: 2px;
  border: 1px solid #e3e3e3;
}

.form-group .radio-custom,
.form-group .checkbox-custom {
  margin: 0 15px 0 0;
}

.form-group .radio-custom > i.checked:before,
.form-group .radio-custom .checkbox-custom > i.checked:before,
.form-group .checkbox-custom > i.checked:before,
.form-group .checkbox-custom .checkbox-custom > i.checked:before {
  color: #1f92e2;
}

.form-group .btn {
  height: 28px;
  line-height: 28px;
  padding: 0 15px;
}

.form-group .btn.btn-default {
  color: #f2f2f2;
  background: #fff;
  box-shadow: none;
}

.form-group .btn.btn-info {
  background: #1f92e2;
}

.ul-checkbox ul {
  padding-left: 20px;
  list-style: none;
}

.ul-checkbox ul li {
  position: relative;
}

.ul-checkbox ul li label {
  margin-bottom: 0;
}

.ul-checkbox .ul-checkbox-two > li:before, .ul-checkbox .ul-checkbox-two > li::before {
  content: '';
  display: block;
  position: absolute;
  top: 18px;
  left: 5px;
  bottom: 15px;
  border-left: 1px dashed #ccc;
}

.ul-checkbox .ul-checkbox-three > li:before, .ul-checkbox .ul-checkbox-three > li::before {
  content: '';
  display: block;
  position: absolute;
  top: 12px;
  left: -10px;
  width: 10px;
  border-top: 1px dashed #ccc;
}

.panel-form {
  padding: 20px;
}

.panel-form:before, .panel-form:after {
  content: " ";
  display: block;
  *zoom: 1;
}

.panel-form:after {
  clear: both;
}

.panel-form .form-group {
  margin-bottom: 20px;
}

.panel-form .form-group .form-text {
  width: 60px;
}

.btn i {
  margin-right: 10px;
  vertical-align: middle;
}

.edit-box {
  border: 1px solid #e3e3e3;
  border-radius: 2px;
}

.edit-box .btn-editor {
  height: 42px;
  background: #fbfbfb;
}

.edit-box .btn-editor:before, .edit-box .btn-editor:after {
  content: " ";
  display: block;
  *zoom: 1;
}

.edit-box .btn-editor:after {
  clear: both;
}

.edit-box .btn-editor .btn-group {
  float: left;
}

.edit-box .btn-editor .btn-group > .btn {
  float: left;
  height: 42px;
  line-height: 18px;
  padding: 15px;
  border: 0;
  background: none;
}

.edit-box .btn-editor .btn-group > .btn i {
  margin: 0;
}

.edit-box .btn-editor .btn-group > .btn.btn-info {
  box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, 0.1);
}

.edit-box .btn-editor .btn-group > .btn.btn-info i {
  color: #333;
}

.edit-box .btn-editor .btn-group > .btn.btn-fontsize i,
.edit-box .btn-editor .btn-group > .btn.btn-fontsize b {
  vertical-align: super;
}

.edit-box .btn-editor .btn-group > .btn.btn-fontsize b {
  position: relative;
  top: -2px;
}

.edit-box .form-editor {
  width: 100%;
  height: 235px;
  border: 0;
  overflow-y: auto;
}

.pagination {
  margin: 10px 0;
  height: 38px;
}

.pagination-page,
.pagination-info,
.pagination-size,
.pagination-jump {
  float: left;
  padding-right: 10px;
  padding-left: 0;
}

.pagination-page > li > a,
.pagination-size > select,
.pagination-group > input,
.pagination-group > button,
.pagination-info {
  font-size: 12px;
  color: #666;
}

/* border */
.pagination-page > li > a,
.pagination-size > select,
.pagination-group > input,
.pagination-group > button {
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #ddd;
}

/* button */
.pagination-page > li > a,
.pagination-group > button {
  cursor: pointer;
  background-color: #fff;
}

.pagination-page > li:hover > a,
.pagination-group > button:hover {
  background-color: #f2f2f2;
  border-color: #ddd;
}

.pagination-page {
  margin: 0;
}

.pagination-page > li {
  float: left;
  margin: 0 5px;
  list-style: none;
}

.pagination-page > li > a {
  height: 28px;
  line-height: 28px;
  min-width: 28px;
  text-align: center;
  margin-left: -1px;
  display: inline-block;
  padding: 0 6px;
  text-decoration: none;
}

.pagination-page > li > a .page-arrow {
  position: relative;
  top: -1px;
  color: #0a5b78;
  font-weight: bold;
  font-family: simsun;
}

.pagination-page > li.active > a {
  color: #333;
  background-color: none;
  border-color: #fff;
  font-weight: bold;
}

.pagination-page > li:first-child > a {
  margin-left: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.pagination-page > li:last-child > a {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.pagination-size > select {
  cursor: pointer;
  height: 28px;
  padding: 0px 2px;
  border-radius: 2px;
}

.pagination-group > input,
.pagination-group > button {
  float: left;
}

.pagination-group > input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  width: 40px;
  height: 28px;
  padding: 0px;
  text-align: center;
}

.pagination-group > button {
  border-left: none;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  height: 28px;
  padding: 0px 7px;
}

.pagination-info {
  height: 28px;
  line-height: 28px;
}

.panel-tab > .panel-heading {
  border-top: 1px solid #e3e3e3;
}

.panel-tab > .panel-heading .nav-tabs > li > a {
  font-size: 14px;
}

.panel-tab > .panel-heading .nav-tabs > li.active > a {
  box-shadow: inset 0 3px 0px 0px #1f92e2;
}

.panel-tab > .panel-body {
  padding: 0;
}

.panel-tab > .panel-body .toolbar-jzy {
  padding: 10px 20px;
}

.iframe-pop {
  width: 100%;
  border: 0;
}

.pop-form .form-group {
  margin: 0 0 10px 0;
}

.pop-form .form-group.form-btn {
  text-align: right;
  margin-bottom: 0;
}

.pop {
  display: none;
  position: fixed;
  z-index: 99;
  top: 50%;
  left: 50%;
  width: 300px;
  max-width: 100%;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background: #fff;
}

.pop .pop-mask {
  content: "";
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  filter: alpha(opacity=60);
  opacity: 0.6;
}

.pop .pop-header {
  position: relative;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #e3e3e3;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: #f4f4f4;
  text-align: center;
}

.pop .pop-header h4 {
  height: 40px;
  line-height: 40px;
  margin: 0 40px;
  font-weight: normal;
  font-size: 16px;
}

.pop .pop-header .pop-close {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
}

.pop .pop-header .pop-close i {
  color: #333;
  font-size: 16px;
}

.pop .pop-body {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: #fff;
}

html.app,
html.app > body {
  overflow: hidden;
}

.body-bg {
  background-color: #ececec;
}

.iframe-jzy {
  position: relative;
  width: 100%;
  height: 100%;
  border: 0;
}

.main-jzy .main-jzy-head {
  position: relative;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
}

.main-jzy .main-jzy-cont {
  position: relative;
  z-index: 1;
  padding: 20px;
}

.main-jzy .main-jzy-cont .main-jzy-cont-null {
  margin-top: 10%;
  color: #999;
  font-size: 12px;
  text-align: center;
}

.main-jzy .main-jzy-cont .main-jzy-cont-null .icon {
  font-size: 50px;
}

.main-jzy .main-jzy-cont .main-jzy-cont-null .btn {
  padding: 12px 35px;
  font-size: 18px;
}

.toolbar-jzy {
  padding: 0 20px;
  border-bottom: 1px solid #e3e3e3;
  background: #fff;
}

.toolbar-jzy:before, .toolbar-jzy:after {
  content: " ";
  display: block;
  *zoom: 1;
}

.toolbar-jzy:after {
  clear: both;
}

.toolbar-jzy .breadcrumb {
  height: 43px;
  line-height: 43px;
  margin-bottom: 0;
  padding: 0;
  border: 0;
}

.toolbar-jzy .breadcrumb > li {
  font-size: 14px;
}

.toolbar-jzy .breadcrumb > li:before, .toolbar-jzy .breadcrumb > li::before {
  display: none;
}

.toolbar-jzy .breadcrumb > li a {
  color: #1f92e2;
}

.toolbar-jzy .breadcrumb > li .icon {
  margin: 0 10px;
  color: #666;
  font-size: 16px;
}

.toolbar-jzy .breadcrumb > li.active {
  color: #333;
}

.toolbar-jzy .speedbtns {
  margin-bottom: 0;
}

.toolbar-jzy .speedbtns li {
  float: left;
  list-style: none;
  margin-left: 15px;
}

.toolbar-jzy .speedbtns li a {
  display: block;
  height: 44px;
  font-size: 12px;
}

.toolbar-jzy .speedbtns li a .icon {
  position: relative;
  top: 14px;
  font-size: 20px;
}

.toolbar-jzy .speedbtns li a:hover {
  opacity: 0.8;
}

.toolbar-jzy .speedbtns li a span {
  position: relative;
  top: 10px;
}

.toolbar-jzy .speedbtns li .btn {
  position: relative;
  top: 5px;
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  font-size: 14px;
}

.toolbar-jzy .filtrate {
  padding: 6px 0;
  font-size: 12px;
}

.toolbar-jzy .filtrate input {
  height: 28px;
  line-height: 28px;
}

.toolbar-jzy .filtrate .text {
  height: 28px;
  line-height: 28px;
  margin-right: 10px;
  color: #999;
}

.toolbar-jzy .filtrate .btn {
  font-size: 12px;
  padding: 5px 18px;
  box-shadow: none;
}

.toolbar-jzy .filtrate .btn-default {
  color: #666 !important;
  border-color: #e3e3e3;
  background: none;
}

.toolbar-jzy .filtrate .btn-default:hover {
  background: #f9f9f9;
}

.toolbar-jzy .filtrate .btn-default.active {
  color: #fff !important;
  box-shadow: none;
  background: #1f92e2;
}

.toolbar-jzy .filtrate .form-control {
  width: 160px;
  height: 28px;
  line-height: 28px;
  padding: 0 12px;
}

.toolbar-jzy .filtrate .daterangepicker-input {
  width: 180px;
  font-size: 12px;
}

.toolbar-jzy .filtrate .choose-shgs {
  line-height: 28px;
  border: 1px solid #e3e3e3;
  background: #f2f2f2;
}

.toolbar-jzy .filtrate .choose-shgs input {
  width: 150px;
  padding: 0 10px;
  margin-right: 20px;
  border: 0;
  outline: 0;
  background: none;
}

.toolbar-jzy .filtrate .choose-shgs a {
  margin-right: 10px;
}
