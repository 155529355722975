.panel-table {
    .panel-heading {
        background: $c1;
        .form-group {
            line-height: 28px;
            margin: 0 20px 0 0;
        }
    }
    .table {
        th,
        td {
            vertical-align: middle;
        }
        thead {
            tr {
                th {
                    padding: 12px 15px;
                    background: $c2;
                    font-weight: normal;
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 10px 15px;
                    color: $fc;
                    .img-sm {
                        width: 24px;
                        height: 24px;
                    }
                    a {
                        &:first-child {
                            margin-left: 0;
                        }
                    }
                    p {
                        position: relative;
                        margin-bottom: 0;
                    }
                }
                .icon-star {
                    i {
                        color: $c4;
                        font-size: 16px;
                    }
                }
                &.newmsg {
                    .dot {
                        position: relative;
                        left: -10px;
                        display: inline-block;
                        width: 6px;
                        height: 6px;
                        margin-left: -6px;
                        border-radius: 50%;
                        background: $blue;
                    }
                }
                .btn-group {
                    cursor: pointer;
                    &.open {
                        .dropdown-toggle {
                            box-shadow: none;
                        }
                    }
                }
                &:hover {
                    background: $c1;
                }
            }
        }
        tfoot {
            tr {
                td {
                    padding: 15px 15px;
                }
            }
        }
    }
}
