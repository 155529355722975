.iframe-pop {
    width: 100%;
    border: 0;
}

.pop-form {
    .form-group {
        margin: 0 0 10px 0;
        &.form-btn {
            text-align: right;
            margin-bottom: 0;
        }
    }
}

.pop {
    display: none;
    position: fixed;
    z-index: 99;
    top: 50%;
    left: 50%;
    width: 300px;
    max-width: 100%;
    border: 1px solid $borc;
    border-radius: 4px;
    background: #fff;
    .pop-mask {
        content: "";
        position: fixed;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        filter: alpha(opacity=60);
        opacity: 0.6;
    }
    .pop-header {
        position: relative;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid $borc;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background: $c2;
        text-align: center;
        h4 {
            height: 40px;
            line-height: 40px;
            margin: 0 40px;
            font-weight: normal;
            font-size: 16px;
        }
        .pop-close {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            height: 40px;
            width: 40px;
            i {
                color: $fc3;
                font-size: 16px;
            }
        }
    }
    .pop-body {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        background: #fff;
    }
}
